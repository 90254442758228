// import { sendEmail } from "./sendEmail"
import { handleAddToFirestore } from "./handleAddToFirestore"
import { handleGetUserByEmail } from "./handleGetUserByEmail"
import { sendToZendesk, generateTemplate } from "./zendeskService"
import { GATSBY_ORDER_ADDITIONAL_TAGS } from "gatsby-env-variables"
import { handleGetDefaultDocuments } from "./handleGetDefaultDocuments"

import { zendeskApi } from "./zendeskService"
import { parseInternalNote } from "../components/Epharmacy/services/parseInternalNote"

const generateTags = (flow, details) => {
  const templateTags = ["Website", "MedGrocer3.0", "Order"]
  let subject, userConfirmationSubject
  if (flow.includes("epharmacy")) {
    templateTags.push("ePharmacy")
    subject = "[MedGrocer 3.0 ePharmacy] Order from"
    userConfirmationSubject = "Your MedGrocer Order"
    if (details?.hasPartnerRequest) templateTags.push("partner_request")
    if (details?.isMedGrocerWellnessPatient)
      templateTags.push("mg_wellness_patient")
  }
  if (process.env.GATSBY_ENV !== "production") templateTags.push("test")
  return {
    tags: [...templateTags, ...(GATSBY_ORDER_ADDITIONAL_TAGS || [])],
    subject,
    userConfirmationSubject,
  }
}

export const sendRequest = async (
  flow, // process ? flexmed or epharmacy
  details, // state
  fileArray, // list of files
  templateConfirmation, // email template
  templateZendesk, // zendesk template
  callback, // success func
  errorCallback // error func
) => {
  let templateObjects = { ...details[flow] }
  const { emailAddress, coupon, medicines } = templateObjects
  const { tags, subject, userConfirmationSubject } = generateTags(
    flow,
    details[flow]
  )
  templateObjects = { ...templateObjects, files: fileArray }
  const requestTemplate = generateTemplate(
    subject,
    emailAddress,
    templateZendesk,
    templateObjects,
    tags
  )

  let internalNote = parseInternalNote({ state: templateObjects })

  try {
    const response = await sendToZendesk(requestTemplate, fileArray)
    try {
      await zendeskApi().put(`/tickets/${response.data.request.id}.json`, {
        ticket: {
          comment: {
            body: `For IT use:\n\n${JSON.stringify(internalNote)}`,
            public: false,
          },
        },
      })
    } catch (error) {}

    let user = await handleGetUserByEmail({ email: emailAddress })

    if (!user) {
      user = await handleAddToFirestore({
        path: [{ type: "collection", path: "users" }],
        document: handleGetDefaultDocuments({
          type: "user",
          document: { ...templateObjects },
        }),
      })
    }

    let orderDocument = await handleAddToFirestore({
      path: [
        { type: "collection", path: "users" },
        { type: "document", path: user.id },
        { type: "collection", path: "orders" },
      ],
      document: handleGetDefaultDocuments({
        type: "order",
        document: { ...templateObjects, zendeskId: response.data.request.id },
      }),
    })

    for (const medicine of medicines) {
      await handleAddToFirestore({
        path: [
          { type: "collection", path: "users" },
          { type: "document", path: user.id },
          { type: "collection", path: "orders" },
          { type: "document", path: orderDocument?.id },
          { type: "collection", path: "medicines" },
        ],
        document: handleGetDefaultDocuments({
          type: "medicine",
          document: medicine,
        }),
      })
    }

    callback()
  } catch (error) {
    errorCallback()
  }
}
