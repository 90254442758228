import { firestore } from "./firebase/firebaseApi"

export const handleAddToFirestore = async ({ document, path }) => {
  let parsedObject = {}

  Object.keys(document).forEach((key) => {
    let dataType = typeof document[key]
    switch (dataType) {
      case "string":
        parsedObject[key] = document[key]
        break
      case "object":
        if (!!document[key]) {
          let objectKeys = Object.keys(document[key])

          if (document[key]?.length) parsedObject[key] = document[key]
          else if (objectKeys?.includes("streetAddress")) {
            parsedObject[key] = {
              streetAddress: document[key]?.streetAddress,
              province: document[key]?.province?.value,
              barangay: document[key]?.barangay?.value,
              city: document[key]?.city?.value,
              notes: document[key]?.notes,
            }
          } else if (objectKeys?.includes("month")) {
            parsedObject[key] = {
              month: document[key]?.month.value,
              date: document[key]?.date.value,
              year: document[key]?.year,
            }
          } else {
            parsedObject[key] = parsedObject[key]?.value
          }
        } else {
          parsedObject[key] = ""
        }
    }
  })

  let result = await firestore.post("/create", {
    document: parsedObject,
    path,
  })
  result = result.data

  let segments = result._path.segments
  let documentId = segments[segments?.length - 1]

  return { ...result, id: documentId }
}
